<template>
  <section class="app-ecommerce-details">


    <!-- Content -->
    <b-card
    >
      <b-card-body>
          <b-row>
              <b-col>
                <form-wizard
                    color="#7367F0"
                    :title="null"
                    :subtitle="null"
                    finish-button-text="Submit"
                    back-button-text="Previous"
                    class="steps-transparent mb-3"
                    @on-complete="formSubmitted"
                    nextButtonText="Suivant"
                    backButtonText="Précédent"
                    finishButtonText="Créer l'inspection"
                    >
                    <!-- account detail tab -->
                    <tab-content
                        title="Ouvrage(s)"
                        icon="feather icon-file-text"
                    >
                        <b-row class="mb-2">
                            <b-col cols="12">
                                <h5 class="mb-0">
                                Liste du ou des ouvrages à inspecter<span style="float:right"><ouvragelistmodal :multi="true" v-on:select-ouvrage="OnSelectOuvrage"/></span>
                                </h5>
                                
                            </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="ml-2 d-flex align-items-center justify-content-center">                         
                            <b-row md="8">
                              <b-col cols="12" md="12">
                                  
                              </b-col>
                            </b-row> 
                          </b-col>
                        </b-row>  
                            <b-row style="height:125px;">
                                  <h6 v-if="selected.length === 0" style="margin:auto;">Aucun ouvrage sélectionné</h6>
                                  <b-card
                                    v-for="ouvrage in selected"
                                    v-bind:key="ouvrage.name"
                                    class="ecommerce-card ml-2 mb-0"
                                    no-body
                                  >
                                  <b-link @click="removeOuvrage(ouvrage.id)">
                                    <div class="item-img text-center">
                                        <b-img
                                          :alt="`${ouvrage.name}`"
                                          fluid
                                          class="card-img-top"
                                          :src="ouvrage.image"
                                          style="width:150px;height:100px;"
                                        />
                                      <h6 class="item-name">
                                          {{ ouvrage.name }}
                                      </h6>                                      
                                    </div>
                                    </b-link>
                                  </b-card>

                        </b-row>
                        <hr/>
                        <h5 class="mb-0">
                        Configuration de l'inspection
                        </h5>
                     
                        <b-row  class="mt-2">
                            <b-col class="align-items-center justify-content-center">
                              <b-form-group
                                label="Type d'inspection"
                                label-for="type"
                                >
                                <v-select
                                    id="type"
                                    v-model="localtype"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="typeName"
                                    :selectable="option => ! option.value.includes('select_value')"
                                    :disabled="selected.length>1 ? true : false"
                                    label="text"
                                    style="width:300px"
                                /> 
                              </b-form-group>                             
                            </b-col>
                            <b-col class="align-items-center justify-content-center">
                              <b-form-group
                                label="Date cible"
                                label-for="i-date"
                                >
                                <b-form-datepicker
                                    id="i-date"
                                    placeholder=""
                                    v-model="inspection.start_date"
                                />
                                <span v-if="localtype.value !== ''">un délai de prévenance de 90 jours est à considérer. La date au plus tôt serait : <b>05/05/2022</b> </span>
                                </b-form-group>                             
                            </b-col>
                            <b-col class="align-items-center justify-content-center">
                                <b-form-group
                                    label="Budget estimatif"
                                    label-for="i-budget"
                                    >
                                    <b-form-input
                                        id="i-budget"
                                        v-model="inspection.budget"
                                        placeholder="15 000"
                                    />
                                    </b-form-group>                       
                            </b-col>                                                        
                        </b-row> 
                        <b-row>
                          <b-col>
                              <b-form-group
                              label="Durée estimative(jours)"
                              label-for="i-duree"
                              >
                              <b-form-input
                                  id="i-duree"
                                  v-model="inspection.duration"
                                  placeholder="5"
                              />
                              </b-form-group>                            
                          </b-col>
                          <b-col>
                                              <b-form-group
                                              label="Inspecteur"
                                              label-for="i-inspecteur"
                                              >
                                              <v-select
                                                  id="i-inspecteur"
                                                  v-model="vinspecteur"
                                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                  :options="inspecteur"
                                                  :selectable="option => ! option.value.includes('select_value')"
                                                  label="text"
                                              />
                                              </b-form-group>  

                          </b-col>
                        </b-row>
                        
                    </tab-content>

                    <!-- address -->
                    <tab-content
                        title="Configuration"
                        icon="feather icon-map-pin"
                    >
                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                            Configuration de l'inspection
                            </h5>
                            <small class="text-muted">Veuillez définir les composants de cette inspection.</small>
                        </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" md=5 class="ml-3 overflow-auto" style="max-height:500px;">
                            <h6 classs="mb-2">Equipements sur ouvrage</h6>

                            <b-table
                                ref="refUserListTable"
                                class="position-relative"
                                :items="Composants.itemsComposant"
                                responsive
                                :fields="fieldsComposant"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found"
                              >

                                <template #cell(releve)="data">
                                  <div class="text-nowrap">
                                    <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                                  </div>
                                </template>
                                <!-- Column: Role -->
                                <template #cell(type)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                                  </div>
                                </template>

                                <!-- Column: Role -->
                                <template #cell(iqoa)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.iqoa }}</span>
                                  </div>
                                </template>
                  

                              </b-table>
                              <hr/>
                              <h6 classs="mb-2">Equipements sous ouvrage</h6>
                            <b-table
                                ref="refUserListTable1"
                                class="position-relative"
                                :items="Composants.itemsComposant1"
                                responsive
                                :fields="fieldsComposant"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found"
                              >
                                 <template #cell(releve)="data">
                                  <div class="text-nowrap">
                                    <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                                  </div>
                                </template>
                                <!-- Column: Role -->
                                <template #cell(type)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                                  </div>
                                </template>


                                <!-- Column: Role -->
                                <template #cell(iqoa)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.iqoa }}</span>
                                  </div>
                                </template>                 

                              </b-table>
                              <hr/>
                              <h6 classs="mb-2">Elements de protection</h6>
                            <b-table
                                ref="refUserListTable2"
                                class="position-relative"
                                :items="Composants.itemsComposant2"
                                responsive
                                :fields="fieldsComposant"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found"
                              >
                                <template #cell(releve)="data">
                                  <div class="text-nowrap">
                                    <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                                  </div>
                                </template>
                                <!-- Column: Role -->
                                <template #cell(type)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                                  </div>
                                </template>


                                <!-- Column: Role -->
                                <template #cell(iqoa)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.iqoa }}</span>
                                  </div>
                                </template>
                  

                              </b-table>
                              <hr/>
                              <h6 classs="mb-2">Structure</h6>

                            <b-table
                                ref="refUserListTable3"
                                class="position-relative"
                                :items="Composants.itemsComposant3"
                                responsive
                                :fields="fieldsComposant"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found"
                              >
                                <template #cell(releve)="data">
                                  <div class="text-nowrap">
                                    <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                                  </div>
                                </template>
                                <!-- Column: Role -->
                                <template #cell(type)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                                  </div>
                                </template>

                                <!-- Column: Role -->
                                <template #cell(iqoa)="data">
                                  <div class="text-nowrap">
                                    <span class="align-text-top text-capitalize">{{ data.item.iqoa }}</span>
                                  </div>
                                </template>                 

                              </b-table>                         
                            </b-col>
                            <b-col cols="6" md=6 class="ml-3" >
                              <h6>Package documentaire de l'inspection</h6>
                              <b-row>
                                  <b-col cols="8" class="m-2 d-flex align-items-center justify-content-center" >
                                      
                                            <b-list-group>
                                              <b-list-group-item>Fiche inspection</b-list-group-item>
                                              <b-list-group-item>Relevé graphique</b-list-group-item>
                                              <b-list-group-item>Rapport inspection ID du 12/09/2012</b-list-group-item>
                                              <b-list-group-item>Fiche consolidée du 12/09/2012</b-list-group-item>
                                            </b-list-group>              
                                  </b-col>
                                  <b-col class="m-1">
                                            <b-button
                                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                              variant="outline-primary"
                                          >
                                              Ajouter un document
                                          </b-button>
                                  </b-col>
                              </b-row>   
                            </b-col>
                        </b-row>
                    </tab-content>

                    <!-- personal details -->
          

                    <!-- personal details -->
                    <tab-content
                        title="Roles/intervenants"
                        icon="feather icon-user"
                    >
                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                            Définitions des rôles et des intervenants pour cette inspection
                            </h5>
                            <small class="text-muted">Veuillez choisir le type d'inspection dans la liste.</small>
                        </b-col>
                        </b-row>
                        <h6>Affectation des pré-requis</h6>
                        <b-row  class="mb-4">

                            <b-table
                                ref="refUserListTable"
                                class="position-relative"
                                :items="itemsRoles"
                                :fields="fieldsRoles"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found">
                              
                                 <template #cell(responsable)="data">
                                    <v-select
                                      id="i-type1"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="responsable"
                                      :selectable="option => ! option.value.includes('select_value')"
                                      label="text"
                                      auto
                                   />  
                                </template>                 
                                 <template #cell(checkbx)="data">
                                  <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                                </template>       
                              </b-table>

                        </b-row>     
                        <h6>Définition des intervenants</h6>

                        <b-row  class="mb-4" >


                            <b-table
                                ref="refUserListTable"
                                class="position-relative"
                                :items="itemsIntervenants"
                                :fields="fieldsIntervenants"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found">
                              
                                 <template #cell(intervenant)="data">
                                    <v-select
                                      id="i-type1"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="data.item.societe === 'ADP' ? interadp : interveritas"
                                      :selectable="option => ! option.value.includes('select_value')"
                                      label="text"
                                   />  
                                </template>                 

                              </b-table>


                        </b-row>                         
                    </tab-content>                                        

                    <!-- social link -->
                    <tab-content
                        title="Validation"
                        icon="feather icon-link"
                    >
                        <b-row>
                        <b-col
                            cols="12"
                            class="mb-2"
                        >
                            <h5 class="mb-0">
                            Validation de cette nouvelle inspection.
                            </h5>
                            <small class="text-muted">La création de cette inspection induira l'inscription dans les plannings des tâches et jalons suivants</small>
                        </b-col>
                        </b-row>
                        <b-row  class="mb-4">
                            <b-table
                                ref="refUserListTable"
                                class="position-relative"
                                :items="itemsTaches"
                                responsive
                                :fields="fieldsTaches"
                                primary-key="id"
                                show-empty
                                empty-text="No matching records found">
                              
                  

                              </b-table>
                        </b-row>    
                    </tab-content>
                    </form-wizard>                  
              </b-col>
          </b-row>
        <b-row>

     
                                

        </b-row>
      </b-card-body>         
    </b-card>
  </section>
</template>


<script>
import { useRouter } from '@core/utils/utils'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import ouvragelistmodal from '@/components/OuvrageListModal.vue'
import Composants from '@/@fake-db/data/apps/composant.js'
import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink,BButtonToolbar, BButtonGroup, 
  BButton, BDropdown, BDropdownItem, BAlert,BFormGroup, BFormInput, BFormCheckbox,BForm, BFormSelect,
  BListGroup, BListGroupItem, BFormDatepicker,BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
   props: {
    selected: {        
        type: Array,
            default: () => []
        },    
   },
   data() {
     return {
       vinspecteur:{text:'',value:''},
       localtype:{text:'',value:''},
       inspection:{
            id: '',
            type: '',
            ouvragelst:'',
            status:"planifié",
            startdate: '',
            enddate: '',
            start_date:'',
            end_date:'', 
            reelstart:'',
            reelbudget:'',      
            budget: '',
            duration: '',
            inspecteur: '',
            composants: 'Tous',
            documents: ['Fiche inspection'],
            trame: 'Rapport standard',
            contraintes:[{label:'Balisage de la route',tiers:'DREAL',delai:'60',resp:'MOA'},{label:'Information',tiers:'SNCF',delai:'90',resp:'Inspecteur'}],
            taches:[{responsable:"Veritas/Michel Durand",deadline:"05/02/2022",tache:"Prévenance DREAL pour balisage route", status:"A faire"},{responsable:"ADP/Gérard Dupont",deadline:"05/01/2022",tache:"Prévenance SNCF - Information inspection", status:"OK"}],
            roles: [{role:'MOA',societe:'ADP',personne:'Gérard Dupont'},{role:'Inspecteur',societe:'Veritas',personne:'Michel Durand'},{role:'Valideur',societe:'ADP',personne:'Gérard Dupont'}],
       },
       newOuvrage: {        
        type: Array,
            default: () => []
        }, 
      found: {
        type: Boolean,
            default: () => false
      } ,
      tempType: '',
      typeName: [
        { value: 'select_value', text: 'Sélectionner' },
        { value: 'ID', text: 'ID - Inspection détaillée' },
        { value: 'CA', text: 'CA - Controle annuel' },
        { value: 'VE', text: 'VE - Visite d\'évaluation' },
        { value: 'IDP', text: 'IDP - Inspection détaillée Périodique' },
      ],    
      inspecteur: [
        { value: 'select_value', text: 'Sélectionner' },
        { value: 'veritas', text: 'Veritas' },
        { value: 'adp', text: 'ADP' },
        { value: 'ins1', text: 'Inspecteur 1' },
        { value: 'ins2', text: 'Inspecteur 2' },
      ],   

        fieldsTaches : [
          { key: 'action',label:"Action", thStyle: { height: "30px"} },
          { key: 'planning',label:"Planning", sortable: true, thStyle: { height: "30px" } },
          { key: 'date',label:"Echéance", sortable: true , thStyle: { height: "30px" }},           
        ] ,    
        
        itemsTaches : [
          {action:"Prévenance DDE - Balisage des routes",planning:"Gérard Dupont",date:"20/08/2022"},
          {action:"Prévenance SNCF - Information",planning:"Michel Durant",date:"20/07/2022"},  
     
        ] ,   
        
        fieldsRoles : [
          { key: 'checkbx',label:"", thStyle: { height: "30px"} },
          { key: 'contrainte',label:"Contrainte/Moyen",  thStyle: { height: "30px" } },
          { key: 'tiers',label:"Tiers",thStyle: { height: "30px" }},  
          { key: 'delai',label:"Délai de prévenance",  thStyle: { height: "30px" } },
          { key: 'responsable',label:"Responsable",thStyle: { height: "30px" }},                     
        ] ,    
        
        itemsRoles : [
          {checkbx:'',contrainte:"Balisage de la route",tiers:"DREAL",delai:"60",responsable:''},
          {checkbx:'',contrainte:"Information",tiers:"SNCF",delai:"90",responsable:''},
     
        ] ,         

        fieldsIntervenants : [
          { key: 'role',label:"Rôle", thStyle: { height: "30px"} },
          { key: 'societe',label:"Société / Service", sortable: true, thStyle: { height: "30px" } },
          { key: 'intervenant',label:"Intervenant", sortable: true , thStyle: { height: "30px" }},           
        ] ,    
        
        itemsIntervenants : [
          {role:"MOA",societe:"ADP",intervenant:""},
          {role:"Inspecteur",societe:"Veritas",intervenant:""},  
         {role:"Valideur rapport",societe:"ADP",intervenant:""},  
        ] ,             

        fieldsComposant : [
          { key: 'releve',label:"", thStyle: { height: "30px", width:"3%" } },
          { key: 'type',label:"Type", sortable: true, thStyle: { height: "30px" } },
          { key: 'iqoa',label:"IQOA", sortable: true , thStyle: { height: "30px" }},           
        ] ,        
      responsable: [
        { value: 'select_value', text: 'Sélectionner' },
        { value: 'mao', text: 'MOA' },
        { value: 'ins', text: 'Inspecteur' },
        { value: 'Valid', text: 'Valideur Rapport' },
      ],  
      interadp: [
        { value: 'select_value', text: 'Sélectionner' },
        { value: 'mao', text: 'ADP / Gérard Dupont' },
        { value: 'ins', text: 'ADP / John Doe' },
        { value: 'Valid', text: 'ADP / Cathy Durand' },
      ], 
      interveritas: [
        { value: 'select_value', text: 'Sélectionner' },
        { value: 'mao', text: 'Veritas / Michel Durand' },
        { value: 'ins', text: 'Veritas / Francois Michel' },
        { value: 'Valid', text: 'Veritas / Jim Kenny' },
      ],                           
     }
   },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    vSelect,
    BButtonToolbar,
    BButtonGroup,    
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup, 
    BFormInput, 
    BFormCheckbox,
    BForm,
    BFormSelect,
    FormWizard, 
    BTable,
    TabContent,
    BListGroup, 
    BListGroupItem, 
    BFormDatepicker,
    // SFC
    ToastificationContent,
    ouvragelistmodal,
  },

  setup() {
    return {
      Composants
    }
  },

  methods: {
    formSubmitted() {
      this.inspection.type = this.localtype.value
      this.inspection.inspecteur = this.vinspecteur.text
      this.inspection.startdate = this.formatDate(this.inspection.start_date)
      this.saveInspection()

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Inspection crée avec succès',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      setTimeout(() => {
      this.$router.push({ name: 'apps-inspection' })
      }, 500);
    },

    saveInspection: function() {
        this.$http.post('/apps/inspection/inspections',this.inspection) 
          .then(response => {
              
          }) 
          .catch((e) => console.log(e))
    },

    OnSelectOuvrage: function (arrayOuvrage) { 
      this.found = false   
        for (const [index, [, value]] of Object.entries(Object.entries(this.selected))) {
          if (value.name == arrayOuvrage.name) {
            this.found = true
          }
        }
        if (!this.found)  
          this.selected.push(arrayOuvrage)

        if (this.selected.length > 1) {
            this.localtype = {text:'CA - Controle annuel',value:'CA'}
        }  
        let tmpArray = []
        this.selected.forEach(a => tmpArray.push(a.name))
        this.inspection.ouvragelst = tmpArray.join(',')
    },

    formatDate: function(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('/');
    },

    removeOuvrage: function (ouvrageId) {   
      let ouvrageIndex = this.selected.findIndex(e => e.id === ouvrageId)
      this.selected.splice(ouvrageIndex,1)
    },    
   
  },
}
</script>

<style lang="scss" >
.nopad th, .nopad td {
    padding: 0px;
}
.ouvrage-list {
    height: 800px; /* or any value */
    overflow-y: auto;
}
div.minimalistBlack {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.divTable.minimalistBlack .divTableCell, .divTable.minimalistBlack .divTableHead {
  padding: 5px 4px;
}
.divTable.minimalistBlack .divTableBody .divTableCell {
  font-size: 13px;
}
.divTable.minimalistBlack .divTableRow:nth-child(even) {
  background: #D0E4F5;
}
.divTable.minimalistBlack .divTableHeading {
  background: #7367F0;
  background: -moz-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: -webkit-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: linear-gradient(to bottom, #968df4 0%, #8176f1 66%, #7367F0 100%);
}
.divTable.minimalistBlack .divTableHeading .divTableHead {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: left;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
}
/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group;}
.viewer {
  background-color:#D3E1FF;
}
.viewer p {
  color:#a81414;
  font-size: 14px;
  font-weight: bold;
  padding:100px;
}
.wizard-header{
  padding:0px !important;
}
</style>
