<template>
    <div ref="ouvragelistmodal">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-login
            variant="outline-primary"
            v-if="!multi"
        >
            Sélectionner un ouvrage
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-login
            variant="outline-primary"
            v-if="multi"
        >
            Sélectionner un ou plusieurs ouvrages
        </b-button>        
        <b-modal
        id="modal-login"
        modal-class="ouvrage-modal nopad"
        cancel-variant="outline-secondary"
        ok-title="Sélectionner"
        @ok=sendSelected
        cancel-title="Fermer"
        centered
        title="Liste des ouvrages"
        >
        <chartedit :fields="fieldsOuvrage" :value="ouvrages" :addbutton=false :editable="false" v-on:addouvrage="onAddOuvrage" v-on:toggle-box="onToggleBox"/>
        </b-modal>
    </div>
</template>

<script>

import {
BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import chartedit from './ChartEdit.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Assettypes from '@/@fake-db/data/apps/assettype.js'

export default {
  directives: {
    Ripple,
  },    
  name: "ouvragelistmodal",
  components: {BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,chartedit},
  methods: {
    onAddOuvrage: function (arrayOuvrage) {
        this.$emit('select-ouvrage', arrayOuvrage)
        this.hideModal('modal-login')
    },
    onToggleBox: function (inouvrage) {

        for (const [index, [, value]] of Object.entries(Object.entries(this.ouvrages))) {
            if (value.name == inouvrage.name) {
                this.ouvrages[index].ischecked = !this.ouvrages[index].ischecked
            }
        }
    },   
    sortOuvragelist: function(a, b) {
      if (a.id > b.id) 
        return -1
      if (a.id < b.id)
        return 1
      return 0
    },


    sendSelected: function () {
        for (const [index, [, value]] of Object.entries(Object.entries(this.ouvrages))) {
            if (this.ouvrages[index].ischecked) {
                this.$emit('select-ouvrage', this.ouvrages[index])
                this.ouvrages[index].ischecked = false
            }
        }
        this.hideModal('modal-login')
    },    
    hideModal (id) {
        this.$root.$emit('bv::hide::modal',id)
    },
    addOuvrage: function (ouvrage) {
        if (ouvrage.type !== 'worksite')
          this.ouvrages.push({id:ouvrage.id, name:ouvrage.name, iqoa:ouvrage.iqoa, type:this.Assettypes[ouvrage.type].name, image:ouvrage.image});

    },    
  },
  mounted() {
 
    if (this.multi) {
        this.fieldsOuvrage = [
                { key: "chbx", label: "", type: "checkbox", thStyle: { width: "8%" } },
                { key: "image", label: "", type: "image", thStyle: { width: "20%" }},
                { key: "name", label: "Nom de l'ouvrage", type: "text" },
                { key: "type", label: "Type", type: "text", thStyle: { width: "30%" }  },
                { key: "iqoa", label: "IQOA", type: "text", thStyle: { width: "8%" } },
            ]
    } 
  },  
 
  props: {
    multi: {        
        type: Boolean,
            default: () => true
        },    
   },
   setup() {
     return {
       Assettypes
     }
   },
   data() { 

     store
      .dispatch('app-aloha/fetchAssets', {
      // q: searchQuery.value,
        q: '',
        parentid:this.$userContext.uoId
        /*perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,*/
      })
      .then(response => {
        let ouvrages = response.data.assets
        ouvrages.forEach(ouvrage => this.addOuvrage(ouvrage));
        this.currentab = true
        this.ouvrages.sort( this.sortOuvragelist ); 
      })
      .catch((e) => {
        console.log(e)   
      }) 


       return {
            fieldsOuvrage: [
                { key: "image", label: "", type: "image", thStyle: { width: "20%" }},
                { key: "name", label: "Nom de l'ouvrage", type: "text" },
                { key: "type", label: "Type", type: "text", thStyle: { width: "30%" }  },
                { key: "iqoa", label: "IQOA", type: "text", thStyle: { width: "8%" } },
            ],
            ouvrages: [],              
       }
   }
  };
</script>

<style>
.ouvrage-modal .modal-dialog{
    max-width: 800px;
}
.nopad th, .nopad td {
    padding: 0px;
}

.ouvrage-modal .modal-body          { overflow: auto; height: 600px;padding:0; }
.ouvrage-modal .modal-body  thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
.ouvrage-modal table  { border-collapse: collapse; width: 100%; }
.ouvrage-modal th     { background:#eee; }
.ouvrage-modal .custom-checkbox{
    margin-left: 15px;
}
</style>


